<template>
  <div
    style="
      position: relative;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      max-width: 1920px;
      height: calc(100vh - 80px);
      overflow-y: auto;
      box-sizing: border-box;
    "
  >
    <div style="flex: 1;height: calc(100vh - 180px); overflow-y: auto;">
      <!-- title-->
      <img
        src="../../assets/image/arrow_back.png"
        style="cursor: pointer; width: 36px; height: auto;margin-left: 100px;margin-top: 20px;"
        @click="goBack"
      />


      <div class="question-title">
        <p>{{ question_title }}</p>
      </div>

      <!--options-->
      <div>
        <div
          v-for="(option, index) in options"
          :key="index"
          class="option-item"
        >
          {{ "(" + formatOptionIndexToString(index) + ") " + option }}
        </div>
      </div>
      <!--AI result-->
      <div style="text-align: left; padding-left: 100px">
        Correct answer : {{ formatOptionIndexToString(answerIndex) }}
      </div>
      <div>
        <div
          v-for="(item, index) in aiResults"
          :key="index"
          :class="item.role == 'assistant' ? 'ai-answer-item' : 'option-item'"
        >
          <p v-html="item.content"></p>
        </div>
      </div>
      <div style="width: 100%; height: 120px"></div>
    </div>
    <div class="inputContainer">
      <el-input
        @keyup.native.enter="sendMessage"
        :autosize="{ minRows: 2, maxRows: 4 }"
        type="textarea"
        v-model="currentM"
        class="myInput"
        placeholder="If you need further clarification, please type your question here."
      ></el-input>
      <el-button
        type="primary"
        size="small"
        style="margin-left: 20px; width: 100px; height: 40px"
        @click="sendMessage"
        >Send</el-button
      >
    </div>
  </div>
</template>
  
  <script>
import { aiQuestion } from "../../api/eae";
import Cookie from "js-cookie";

import { Loading } from "element-ui";

export default {
  name: "index",
  data() {
    return {
      question_title: "",
      options: [],
      answer: "",
      answerIndex: 0,
      currentM: "",
      aiResults: [],
      aiRequestContents: [],
      firstAIRequest: {},
    };
  },
  mounted() {
    this.question_title = Cookie.get("question_title");
    this.answerIndex =parseInt(Cookie.get("answer"))
    this.options = JSON.parse(Cookie.get("options"));
    this.fetchData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fetchData() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.8)",
        spinner: "el-icon-loading",
        text: "Waitig response",
      });
      let str_option = "";
      for (let i = 0; i < this.options.length; i++) {
        let index = this.formatOptionIndexToString(i);
        str_option = str_option + "(" + index + ")" + this.options[i];
        if (i != this.options.length - 1) {
          str_option = str_option + "<br>";
        }
      }
      let answer = this.options[this.answerIndex]
      let json = {
        role: "user",
        content:
          "You are a professional high school academic competition coach. One of your students has brought the following question to seek your help. Please answer in English what this question is examining and provide a detailed and accurate analysis of the thought process.【Question Stem】" +
          this.question_title +
          "【Options】" +
          str_option +
          "【Answer Key】" +
          answer,
      };
      this.firstAIRequest = json;
      let array = [];
      array.push(json);
      aiQuestion(array).then((res) => {
        this.aiResults.push({
          role: "assistant",
          content: res.data.data,
        });
        this.currentM = "";
        loadingInstance.close();
      });
    },
    sendMessage() {
      if (this.currentM != "") {
        let content = this.currentM;
        this.aiResults.push({
          role: "user",
          content: content,
        });
        let temp_contents = [];
        temp_contents.push(this.firstAIRequest);
        this.aiResults.forEach((item) => {
          temp_contents.push(item);
        });
        let loadingInstance = Loading.service({
          background: "rgba(0, 0, 0, 0.8)",
          spinner: "el-icon-loading",
          text: "Waitig response",
        });
        aiQuestion(temp_contents).then((res) => {
          this.aiResults.push({
            role: "assistant",
            content: res.data.data,
          });
          this.currentM = "";
          loadingInstance.close();
        });
      } else {
        this.$message.warn("请输入内容");
      }
    },
    formatOptionIndexToString(index) {
      switch (index) {
        case 0:
          return "A";
        case 1:
          return "B";
        case 2:
          return "C";
        case 3:
          return "D";
        case 4:
          return "E";
        case 5:
          return "F";
        case 6:
          return "G";
        case 7:
          return "H";
      }
    },
  },
};
</script>
  
  <style scoped lang="less">
.inputContainer {
  width: 100%;
  height: 120px;
  position: fixed;
  max-width: 1920px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.myInput {
  width: 600px;
}

.question-title {
  padding: 0px 100px;
  width: 100%;
  text-align: left;
  margin-top: 20px;
  font-size: 16px;
  // font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
}

.option-item {
  padding: 13px 100px;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  background: #ffffff;
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
  color: #333333;
}

.ai-answer-item {
  .option-item ();
  background-color: #f5f5f5;
  margin-top: 25px;
}
</style>
  